import { Dialog } from '@angular/cdk/dialog';
import { Platform } from '@angular/cdk/platform';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { Account } from '@app/models/authentication/account.model';
import { NotificationHeader } from '@app/models/notification-header.model';
import { AccountValidationModalComponent } from '@app/modules/authentication/account-validation-modal/account-validation-modal.component';
import { LoginModalComponent } from '@app/modules/modals/login-modal/login-modal.component';
import { LoginObservable } from '@app/observables/login.observable';
import { NotificationObservable } from '@app/observables/notification.observable';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [RouterLink],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.sass',
})
export class FooterComponent implements OnInit, OnDestroy {
    currentAccount: Account | null = null;
    listSubscription: Subscription[] = [new Subscription(), new Subscription()];
    notifications: number = 0;
    notificationHeader: NotificationHeader | null = null;
    private readonly _platform: Platform = inject(Platform);
    private notificationObservable: NotificationObservable = inject(
        NotificationObservable
    );

    constructor(
        private dialog: Dialog,
        private loginObservable: LoginObservable,
        private localStorageService: LocalStorageService,
        private router: Router
    ) {
        this.currentAccount = this.loginObservable.getData();
    }

    ngOnInit(): void {
        this.getCurrentAccount();
        this.subscriptionNotification();
    }

    ngOnDestroy() {
        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    updateNotification(): void {
        if (!this.notificationHeader) return;

        this.notificationHeader.openChanges = 0;
        this.notificationObservable.updateData(this.notificationHeader);
    }

    addPublication(): void {
        if (!this.currentAccount?._id) {
            const dialogRef = this.dialog.open(LoginModalComponent);
            return;
        }

        if (!this.currentAccount?.isVerified) {
            const dialogRef = this.dialog.open(AccountValidationModalComponent);

            dialogRef.closed.subscribe(() => {
                this.router.navigate(['/autenticacion/registrarTelefono']);
            });

            return;
        }
        this.localStorageService.setItem('goBackTo', this.router.url);
        this.router.navigate(['/publicacion/crear']);
    }

    private subscriptionNotification(): void {
        if (!this._platform.isBrowser) return;

        this.listSubscription[0] = this.notificationObservable.data$.subscribe(
            (res: NotificationHeader | null) => {
                if (!res || res.openChanges == undefined) return;

                this.notifications = res.openChanges;
                this.notificationHeader = res;
            }
        );
    }

    private getCurrentAccount(): void {
        this.listSubscription[1] = this.loginObservable.data$.subscribe(
            (res: Account | null) => {
                this.currentAccount = res;
            }
        );
    }
}

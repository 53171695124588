import { Component } from '@angular/core';

import { appConfig } from '@environments/environment';

@Component({
    selector: 'app-offline',
    standalone: true,
    imports: [],
    templateUrl: './offline.component.html',
    styleUrl: './offline.component.sass',
})
export class OfflineComponent {
    public logoSantoVecino = appConfig.logo;
}

<div class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center;">
    <div class="small-container m-auto">
        <img
            alt="logo santo vecino"
            class="login-logo m-auto d-flex spacing"
            height="50%"
            src="{{ logoSantoVecino }}"
            width="50%" />
        <h1 class="text-center title-section m-auto">Santo Vecino se encuentra en mantenimiento</h1>
        <p class="text-center">
            Estamos trabajando para ofrecerle una mejor experiencia, lamentamos el inconveniente
        </p>
    </div>
</div>

import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { debounceTime, Subscription } from 'rxjs';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NotificationComponent } from './components/notification/notification.component';
import { SnackBarVicenteComponent } from './components/snack-bar-vicente/snack-bar-vicente.component';
import { TipComponent } from './components/tip/tip.component';
import { scrollReset } from './functions/scroll-position.function';
import { NotificationSV } from './models/notification-sv.model';
import { SpinnerComponent } from './modules/modals/spinner/spinner.component';
import { LoginObservable } from './observables/login.observable';
import { ScrollObservable } from './observables/scroll.observable';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { NavigationService } from './services/navigation/navigation.service';
import { ShareInfoService } from './services/share-info/share-info.service';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { ErrorHttp } from './models/error-http.model';
import { viewError } from './functions/view-error-user.function';
import { ProfileService } from './providers/profile/profile.service';
import { Account } from './models/authentication/account.model';
import { CommonService } from './providers/common/common.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        SnackBarVicenteComponent,
        RouterOutlet,
        NotificationComponent,
        SpinnerComponent,
        TipComponent,
        RecaptchaV3Module,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.sass',
})
export class AppComponent implements OnInit, OnDestroy {
    public windowOnTop: boolean = true;
    title = 'Santo Vecino';
    version = 'Santo Vecino';
    data: any;
    reloadHeader: number = 0;
    listSubscription: Subscription[] = [];
    notificationData: NotificationSV;
    viewLoading: boolean = false;
    recaptcha: boolean = true;

    public navigationService = inject(NavigationService);
    private readonly _platform: Platform = inject(Platform);
    private readonly scrollObservable: ScrollObservable = inject(ScrollObservable);
    private readonly localStorageService: LocalStorageService = inject(LocalStorageService);

    private readonly profileService: ProfileService = inject(ProfileService);

    private readonly shareInfoService: ShareInfoService = inject(ShareInfoService);
    private readonly router: Router = inject(Router);
    private readonly loginObservable: LoginObservable = inject(LoginObservable);
    private readonly googleTagManagerService: GoogleTagManagerService =
        inject(GoogleTagManagerService);
    private commonService: CommonService = inject(CommonService);
    private lastExecutionTime: number = 0;

    constructor() {
        this.listSubscription = [
            new Subscription(),
            new Subscription(),
            new Subscription(),
            new Subscription(),
        ];

        this.scrollObservable.updateData(0);
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: any) {
        event.returnValue = '';
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        const now = Date.now();
        let lastPosition: number = 0;
        const scrollTop = window.scrollY || window.pageYOffset;
        if (scrollTop != 0) this.scrollObservable.updateData(scrollTop);

        const topIsWithinRange = scrollTop >= 0 && scrollTop <= 90;
        this.windowOnTop = topIsWithinRange;

        const scrollHeight = document.documentElement.scrollHeight;
        const windowHeight = window.innerHeight;
        const distanceToBottom = scrollHeight - (scrollTop + windowHeight);
        const threshold = scrollHeight / 7.5;

        if (now - this.lastExecutionTime < 1000) return;

        if (!(distanceToBottom <= threshold && lastPosition < scrollTop)) return;

        this.lastExecutionTime = now;
        lastPosition = scrollTop;
        this.shareInfoService.scrollGet$.emit();
    }

    ngOnInit() {
        // this.navigationService.navigatePage('mantenimiento');

        if (this._platform.isBrowser) {
            if (environment.production) {
                this.googleTagManagerService.addGtmToDom();
                this.googleTagManager();
            }

            if (!environment.PROXY) this.validateRefreshPage();
        }

        this.subscriptionSpinner();
        this.subscribeResetScroll();

        this.loginObservable.checkLocalStoreData();
    }

    ngOnDestroy() {
        this.listSubscription.forEach(itrSub => {
            itrSub.unsubscribe();
        });
    }

    async validateRefreshPage(): Promise<void> {
        const savedReleaseVersion = this.localStorageService.getItem('release-version', true);
        const releaseVersion: string = await this.getReleaseVersion();

        if (!savedReleaseVersion) {
            this.localStorageService.setItem('release-version', releaseVersion);
            return;
        }

        if (savedReleaseVersion && savedReleaseVersion !== releaseVersion) {
            const currentAccount: Account | null = this.loginObservable.getData();

            this.localStorageService.setItem('release-version', releaseVersion);

            if (currentAccount) {
                this.profileService.userProfile().subscribe(
                    ({ data }) => {
                        this.loginObservable.updateData({
                            _id: data._id,
                            role: currentAccount?.role!,
                            address: data.address,
                            info: data.info,
                            location: data.location,
                            tutorials: data.tutorials,
                            havePost: currentAccount.havePost,
                            isVerified: currentAccount.isVerified,
                        });

                        location.reload();
                    },
                    ({ code }: ErrorHttp) => {
                        viewError('error', code, this.shareInfoService);
                    }
                );
            }

            return;
        }

        return;
    }

    async getReleaseVersion(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.commonService.getReleaseVersion().subscribe(
                resp => resolve(resp.data.releaseVersion),
                error => reject(error)
            );
        });
    }

    private subscriptionSpinner(): void {
        if (!this._platform.isBrowser) return;

        this.listSubscription[0] = this.shareInfoService.spinner$
            .pipe(debounceTime(250))
            .subscribe((res: boolean) => {
                document.body.style.overflowY = res ? 'hidden' : 'auto';
                this.viewLoading = res;
            });
    }

    private subscribeResetScroll(): void {
        if (!this._platform.isBrowser) return;

        this.listSubscription[1] = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                scrollReset();
            }
        });
    }

    private googleTagManager() {
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url,
                };

                this.googleTagManagerService.pushTag(gtmTag);
            }
        });
    }
}

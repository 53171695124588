export const environment = {
    RECAPTCHA_PUBLIC: '6Lf4GxkqAAAAAGyR-CR0Igj6rF1R4x5CsTODTaQY',

    production: false,
    PROXY: false,
    passwordDevQa: 'sv2024',
    API_URL_V1: 'https://api-qa.santovecino.com/api/v1/',
    API_URL_V2: 'https://api-qa.santovecino.com/api/v2/',

    KEY_CRYPTO: 'W730Mui+POeHM+WuN+Qe[FhKp]QViY6M',
    URL_FILE: 'https://santo-vecino-qa.s3.amazonaws.com/',
    WEB_SOCKET_URL: 'https://api-qa.santovecino.com/notification',
    CATEGORY_FILE: 'https://santovecino.s3.amazonaws.com/',
    URL_SANTO_VECINO: 'https://qa.santovecino.com',
    VAPID_PUBLIC_KEY:
        'BHGzXf74WGndRE9FV5qmaTrjLNyKj9Rhc5k47ijLp0SjQLpzRAlTPzt3_7UP9InDpeji5ryJ0dDMxN2EIE7jU14',
    VAPID_PRIVATE_KEY: 'G6pT9nqhvTqSGH_JwTV62znG9YVlNyCG5LoJgn_ENtA',
    ALGOLIA: {
        appId: 'DBMX1L1JNA',
        apiKey: 'ea3c72a6e109a6d723be9ab6582c0956',
        indexName: 'qa-santovecino',
        indexNameCategory: 'qa-category',
        urlSync: false,
    },

    GOOGLE_CLIENT_ID:
        '752925264395-6ns291urcn77a3hbib3tt0e1rhfgm3je.apps.googleusercontent.com',
    GOOGLE_SECRET: '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',
    GOOGLE_CALL_BACK_URL:
        'https://api-dev.santovecino.com/api/auth/google/redirect',
};

export const googleTagManager = {
    id: 'GTM-W5K2V6PN',
};

export const appConfig = {
    logo: 'assets/logo/logo_estandar.svg',
    video: {
        prehome: 'assets/videos/prehome/prehome.m3u8',
    },
};
